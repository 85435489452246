import React from "react";
import Header from '../components/header.js';
import Footer from '../components/footer.js';
import {Card} from '../components/card.js';
import divider from '../../static/assets/images/Main-DottedDivider.png';
import step1 from '../../static/assets/images/IMG_1754.png';
import step2 from '../../static/assets/images/IMG_1753.png';
import "../components/collection.min.css";

export default ( {data} ) => {

    let nodes = data.allMarkdownRemark.edges.map(item => item.node);
    const mysteries = nodes.map((mystery, i) => {
        return (
            <Card key={i} mystery={mystery} />
        )
    })
    // let allInsta = data.allInstaNode.edges.sort((a, b) => {
    //   return b.node.timestamp - a.node.timestamp;
    // })
    // let insta = allInsta.map((img) => {
    //   return img.node.localFile.childImageSharp.fluid;
    // }).slice(0,4)
    return (
        <div>
            <Header />
            <div 
              className="popup-container"
              onClick={() => {
                document.querySelector('.popup-container').classList.remove('active');
                document.querySelector('html').style.overflowY = "auto";
                window.localStorage.setItem('omtl_app', 1);
              }}
            >
              <div className="app-modal">
                <h4>Add <i>On a Mission to Love</i> to your apps!</h4>
                <img src={step1}/>
                <img src={step2} />
                <div 
                  className="button-ok"
                  onClick={() => {
                    document.querySelector('.popup-container').classList.remove('active');
                    window.localStorage.setItem('omtl_app',1);
                  }}
                >OK!</div>
              </div>
            </div>
            <div className="wrapper-1080">
                <h2>Mysteries</h2>
                <div className="image-divider" style={{'minHeight':'12px'}}>
                  <img src={divider} />
                </div>
                <p>Select a set of mysteries to pray along with.</p>
                <div className="flex-container">
                    {mysteries}
                </div>
            </div>
            <Footer siteTitle={'On a Mission to Love'}/>
        </div>
    )
}

export const CollectionQuery = graphql`
query CollectionQuery($collection: String!) {
  allMarkdownRemark(filter: {fields: {collection: {eq: $collection }}}) {
    edges {
      node {
        html
        fields {
          slug
          collection
        }
        frontmatter {
            title
        }
      }
    }
  }
}
`